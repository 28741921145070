import { Button as BaseButton, ButtonType, IconType } from "@justhome/react-ui";
import { WEBSITE_HOST } from "@lib/constants";
import {
  ComponentBlocksButton,
  Maybe,
  PageEntityResponse,
} from "@lib/generated/graphql";
import { ActionCallback, ButtonAction } from "@lib/hooks/actions/types";
import { getPageEntityPath } from "@lib/routing";
import { FC, MouseEvent, MouseEventHandler } from "react";
import { trackEvent } from "@justhome/common";

const isExternalHref = (
  page: Maybe<PageEntityResponse> | undefined,
  href: Maybe<string> | undefined,
): boolean => {
  if (!href) {
    return false;
  }
  if (href.startsWith("#")) {
    return false;
  }

  const pagePath = getPageEntityPath(page);

  /*
   * If we don't link towards internal slug paths (e.g. "/vergleich")
   * */
  if (pagePath === null && !href.startsWith("/")) {
    return (
      href.startsWith("http://") ||
      !href
        .replace(/^(https:\/\/)?(www\.)?/, "")
        .startsWith(WEBSITE_HOST.replace(/^(https:\/\/)?/, ""))
    );
  }
  return false;
};

export interface ButtonProps {
  className?: string;
  type?: ButtonType;
  prefetch?: boolean;
  onClick?: MouseEventHandler;
  onAction?: ActionCallback;
  iconColor?: string;
  paddingX?: boolean;
  isLoading?: boolean;
}

export const Button: FC<ComponentBlocksButton & ButtonProps> = ({
  className,
  page,
  href,
  onClick,
  onAction,
  label,
  trackingId,
  id,
  iconStart,
  iconEnd,
  ...props
}) => {
  const pageHref = props?.action ? "" : (getPageEntityPath(page) ?? href);

  const handleClickButton = (e: MouseEvent) => {
    if (trackingId) {
      trackEvent(trackingId);
    }

    if (props?.action && onAction) {
      onAction(props.action as ButtonAction);
    } else if (onClick) {
      onClick(e);
    }
  };

  return (
    <BaseButton
      onClick={handleClickButton}
      href={pageHref}
      className={className}
      id={trackingId || id || undefined}
      iconStart={iconStart as IconType}
      iconEnd={iconEnd as IconType}
      target={isExternalHref(page, href) ? "_blank" : null}
      {...props}
    >
      {label}
    </BaseButton>
  );
};

export default Button;
