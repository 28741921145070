import { Icon } from "@justhome/react-ui";
import { PageEntityResponse } from "@lib/generated/graphql";
import { getPagePath } from "@lib/routing";
import clsx from "clsx";
import { FC, MouseEventHandler } from "react";
import Link from "../Link";

interface NavBarLinkProps {
  label?: string | null;
  page?: PageEntityResponse | null;
  onClick: MouseEventHandler;
  onMouseEnter: MouseEventHandler;
  isActive?: boolean;
  isLink?: boolean;
}

const NavBarLink: FC<NavBarLinkProps> = ({
  label,
  page,
  isActive,
  isLink,
  onClick,
  onMouseEnter,
}) => {
  const href = getPagePath(page?.data?.attributes) || "";

  if (isLink) {
    return (
      <Link
        onMouseEnter={onMouseEnter}
        className="block text-button-2 py-[6px] px-2xs m:px-s hover:text-primary-825 active:text-primary-875"
        href={href}
      >
        {label || page?.data?.attributes?.title}
      </Link>
    );
  }

  return (
    <button
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      className={clsx(
        "text-button-2 py-[6px] px-2xs m:px-s hover:text-primary-825 active:text-primary-875 focusable rounded-xs flex items-center",
        {
          "text-primary-800": isActive,
        },
      )}
    >
      {label}

      <Icon
        type="Down"
        size={20}
        className={clsx("ml-xs m:ml-2xs", {
          "rotate-180": isActive,
        })}
      />
    </button>
  );
};

export default NavBarLink;
