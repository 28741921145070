import { Icon, IconType } from "@justhome/react-ui";
import { PageEntityResponse } from "@lib/generated/graphql";
import { getPagePath } from "@lib/routing";
import clsx from "clsx";
import { FC } from "react";
import Link from "../Link";

interface NavBarItemProps {
  label?: string | null;
  page?: PageEntityResponse | null;
  isActive?: boolean;
  className?: string;
  icon?: IconType | null;
}

const NavBarItem: FC<NavBarItemProps> = ({ label, page, className, icon }) => {
  const href = getPagePath(page?.data?.attributes);

  if (href) {
    return (
      <Link
        className={clsx(
          "border border-neutral-50 rounded-2xs text-body-2 p-3s hover:text-neutral-0 hover:bg-primary-825 active:bg-primary-875 hover:border-primary-825 active:border-primary-875 flex flex-col gap-2xs items-center group",
          className,
        )}
        href={href}
      >
        {icon && (
          <Icon
            size="28"
            className="text-primary-800 group-hover:text-neutral-0"
            type={icon}
          />
        )}
        {label || page?.data?.attributes?.title}
      </Link>
    );
  }
};

export default NavBarItem;
