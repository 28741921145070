import { ApplicationType } from "@justhome/common/api/core";
import { Locale } from "next/router";
import { DEFAULT_LOCALE, WEBSITE_HOST } from "./constants";
import { Page, PageEntityResponse } from "./generated/graphql";
import addUtmQueryToHref from "./utm/addUtmQueryToHref";

const PATHS = {
  en: {
    learningCenter: "/learning-center",
    article: (slug: string) => `/learning-center/article/${slug}`,
    compare: "/compare",
    budgetCheck: (stepId: string) => `/compare/budget-check/${stepId}`,
    partnerBudgetCheck: (partnerId: string, stepId: string) =>
      `/partner/${partnerId}/compare/budget-check/${stepId}`,
    partnerSearchProfileBudgetCheck: (partnerId: string, stepId: string) =>
      `/partner/${partnerId}/compare/search-profile/${stepId}`,
    partnerMeeting: (partnerId: string) =>
      `/partner/${partnerId}/compare/booking`,
    careers: "/careers",
    career: (slug: string) => `/careers/${slug}`,
    meeting: (meetingType?: string) =>
      `/meeting?meetingType=${meetingType ?? ""}`,
    medalFlow: (partnerId: string, clientMode: string, stepId: string) =>
      `/partner/${partnerId}/compare/${clientMode.toLowerCase()}/financing/${stepId}`,
  },
  de: {
    learningCenter: "/learning-center",
    article: (slug: string) => `/learning-center/article/${slug}`,
    compare: "/vergleich",
    budgetCheck: (stepId: string) => `/vergleich/budget-check/${stepId}`,
    partnerBudgetCheck: (partnerId: string, stepId: string) =>
      `/partner/${partnerId}/vergleich/budget-check/${stepId}`,
    partnerSearchProfileBudgetCheck: (partnerId: string, stepId: string) =>
      `/partner/${partnerId}/vergleich/search-profile/${stepId}`,
    partnerMeeting: (partnerId: string) =>
      `/partner/${partnerId}/vergleich/terminbuchung`,
    careers: "/karriere",
    career: (slug: string) => `/karriere/${slug}`,
    meeting: (meetingType?: string) =>
      `/terminbuchung?meetingType=${meetingType ?? ""}`,
    medalFlow: (partnerId: string, clientMode: string, stepId: string) =>
      `/partner/${partnerId}/vergleich/${clientMode.toLowerCase()}/financing/${stepId}`,
  },
} as const;

export const APPLICATION_TYPE_PATHS: Record<
  string,
  Partial<Record<ApplicationType, string>>
> = {
  en: {
    BUILD_HOUSE: "build-house",
    BUY_WITH_SAVINGS: "buy-with-savings",
    REFINANCING: "refinancing",
  },
  de: {
    BUILD_HOUSE: "haus-bauen",
    BUY_WITH_SAVINGS: "immobilie-kaufen",
    REFINANCING: "anschlussfinanzieren",
  },
  immo: {
    BUILD_HOUSE: "haus-bauen",
    BUY_WITH_SAVINGS: "immobilie-kaufen",
    REFINANCING: "anschlussfinanzieren",
  },
};

const getPath = (locale: string) => PATHS[locale] ?? PATHS[DEFAULT_LOCALE];

export const getCanonicalLink = (locale: Locale, path: string): string => {
  const localePrefix = getLocalePrefix(locale);
  return `${WEBSITE_HOST}${localePrefix}${path}`;
};

export const getLocalePrefix = (locale: Locale) => {
  return locale === DEFAULT_LOCALE ? "" : `/${locale}`;
};

export const getApplicationProcessPath = (
  locale: string,
  subPath?: string,
  query?: Record<string, string>,
  partnerSlug?: string,
): string => {
  let path = getComparePath(locale);
  if (partnerSlug) {
    path = `/partner/${partnerSlug}${path}`;
  }
  if (subPath) {
    path = `${path}/${subPath}`;
  }
  return addUtmQueryToHref(path, query);
};

export const slugify = (text: string) => {
  return encodeURIComponent(
    text
      .replace(/[^\w ]/g, "")
      .trim()
      .replace(/\s+/g, "-")
      .toLowerCase(),
  );
};

export const getPageEntityPath = (page?: PageEntityResponse | null) => {
  return getPagePath(page?.data?.attributes);
};

export const getPagePath = (page?: Page | null): string | null => {
  if (!page?.slug) {
    return null;
  }

  if (page.slug === "/") {
    return "/";
  }
  return `/${page.slug}`;
};

export const getLearningCenterPath = (locale: string) =>
  getPath(locale).learningCenter;

export const getArticlePath = (locale: string, slug: string) =>
  getPath(locale).article(slug);

export const getComparePath = (locale: string) => getPath(locale).compare;

export const getCareersPath = (locale: string) => getPath(locale).careers;

export const getMeetingPath = (locale: string, meetingType?: string) =>
  getPath(locale).meeting(meetingType);

export const getCareerPath = (locale: string, slug: string) =>
  getPath(locale).career(slug);

export const getBudgetCheckPath = (locale: string, stepId: string) =>
  getPath(locale).budgetCheck(stepId);

export const getPartnerBudgetCheckPath = (
  locale: string,
  partnerId: string,
  stepId: string,
) => getPath(locale).partnerBudgetCheck(partnerId, stepId);

export const getPartnerSearchProfileBudgetCheckPath = (
  locale: string,
  partnerId: string,
  stepId: string,
) => getPath(locale).partnerSearchProfileBudgetCheck(partnerId, stepId);

export const getPartnerMeetingPath = (locale: string, partnerId: string) =>
  getPath(locale).partnerMeeting(partnerId);

export const getMedalFlowPath = (
  locale: string,
  partnerId: string,
  clientMode: string,
  stepId: string,
) => getPath(locale).medalFlow(partnerId, clientMode, stepId);

export const replaceLastRouteParam = (route: string, param: string) => {
  const segments = route.split("/").filter((s) => s);
  segments[segments.length - 1] = param;
  return "/" + segments.join("/");
};

export const applicationTypeToPath = (
  locale: string,
  applicationType: ApplicationType | undefined,
) => {
  if (!applicationType) {
    return "";
  }
  return "short/" + (APPLICATION_TYPE_PATHS[locale]?.[applicationType] || "");
};

export const pathToApplicationType = (path: string) => {
  return Object.values(APPLICATION_TYPE_PATHS)
    .map((a) => Object.entries(a))
    .flat()
    .find(([_, p]) => p === path)?.[0];
};
