import {
  PartnerContext,
  PartnerContextValue,
} from "@lib/context/partner/context";
import { useContext } from "react";

const usePartnerContext = () => {
  return useContext<PartnerContextValue>(PartnerContext);
};

export default usePartnerContext;
