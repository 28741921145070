import { Partner } from "@justhome/common/api/core";
import useIsTest from "@lib/hooks/useIsTest";
import clsx from "clsx";
import Image from "next/image";
import React from "react";
import Link from "../Link";

const NavBarLogo: React.FC<{
  href: string;
  partner: Partner | undefined;
  className?: string;
}> = ({ href, partner, className }) => {
  const isTest = useIsTest();
  const logo = isTest ? "logo-test" : "logo-new";
  const logoSrc = `/assets/img/${logo}.svg`;

  return (
    <div
      className={clsx(
        "hover:cursor-pointer h-[28px] m:h-2m flex flex-none items-center",
        className,
      )}
    >
      {partner?.logoUrl && (
        <div className="flex items-center gap-2xs">
          <Link href={partner?.returnUrl || href}>
            <Image
              className="h-m m:h-2m object-contain w-auto max-w-[120px] m:max-w-[148px]"
              width="148"
              height="40"
              loading="eager"
              priority
              src={partner.logoUrl}
              alt=""
            />
          </Link>
          <span className="border-r border-neutral-100 h-3s"></span>
          <Link href="/">
            <Image
              height="24"
              width="102"
              loading="eager"
              priority
              src="/assets/img/logo-new.svg"
              className="w-auto h-2s m:h-3s"
              alt="Justhome"
            />
          </Link>
        </div>
      )}
      {!partner && (
        <Link href="/">
          <Image
            width="170"
            height="40"
            src={logoSrc}
            loading="eager"
            priority
            alt="Justhome"
            className="object-contain object-left h-m m:h-2m w-auto"
          />
        </Link>
      )}
    </div>
  );
};

export default NavBarLogo;
