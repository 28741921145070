import { Transition } from "@headlessui/react";
import { Button, Icon } from "@justhome/react-ui";
import { getPagePath } from "@lib/routing";
import clsx from "clsx";
import { FC } from "react";
import Link from "../Link";
import { NavBarMenuItem, NavBarMenuItemWithIcon } from "./types";

interface NavBarMenuSubMenuProps {
  item: NavBarMenuItem | NavBarMenuItemWithIcon;
  onClick: (item: NavBarMenuItem) => void;
  onBack: VoidFunction;
  activeItems: NavBarMenuItem[];
  isSubMenu?: boolean;
  onClose: VoidFunction;
}

const hasIcon = (
  item: NavBarMenuItem | NavBarMenuItemWithIcon,
): item is NavBarMenuItemWithIcon => {
  return (item as NavBarMenuItemWithIcon).icon !== undefined;
};

const hasSubmenu = (item: NavBarMenuItem): boolean => {
  return !!item.items?.length;
};

const NavBarBackButton: FC<any> = ({ className, onClick, children }) => {
  return (
    <div className={clsx("flex items-center", className)}>
      <Button iconStart="Left" variant="ghost" size="md" onClick={onClick} />
      <div className="text-button-2">{children}</div>
    </div>
  );
};

const NavBarMenuLink: FC<any> = ({
  onClick,
  className,
  label,
  opensSubmenu,
  icon,
  href,
}) => {
  const classes = clsx(
    "w-full px-2s s:px-3s py-[10px] s:py-[14px] text-button-2 font-book text-button-2 border border-neutral-50 rounded-s hover:text-primary-825 active:text-primary-875 flex items-center focusable",
    className,
  );
  const iconElem = icon && (
    <Icon type={icon.type} size="20" className="mr-2xs text-primary-800" />
  );
  const labelElem = (
    <span className="whitespace-nowrap text-ellipsis overflow-hidden">
      {label}
    </span>
  );

  if (opensSubmenu) {
    return (
      <button onClick={onClick} className={classes}>
        {iconElem}
        {labelElem}
        <Icon type="Right" size="20" className="ml-auto text-primary-800" />
      </button>
    );
  }

  return (
    <Link href={href} onClick={onClick} className={classes}>
      {iconElem}
      {labelElem}
    </Link>
  );
};

const NavBarMenuSubMenu: FC<NavBarMenuSubMenuProps> = ({
  item,
  onClick,
  activeItems,
  onBack,
  isSubMenu,
  onClose,
}) => {
  const isActive = activeItems.includes(item);

  return (
    <Transition appear unmount={false} show={isActive}>
      <div className="absolute left-full w-full inset-none bg-neutral-0 z-10 px-s py-3s transition-all duration-500  data-[leave]:opacity-0">
        {isSubMenu && (
          <NavBarBackButton className="mb-2xs" onClick={onBack}>
            {item.label || item.page?.data?.attributes?.title}
          </NavBarBackButton>
        )}
        {
          <ul className={clsx("flex flex-col gap-2xs")}>
            {item.items?.map((childItem, index) => (
              <li key={childItem?.id ?? index}>
                {childItem && (
                  <NavBarMenuLink
                    icon={hasIcon(childItem) ? childItem.icon : null}
                    onClick={() => onClick(childItem)}
                    href={getPagePath(childItem.page?.data?.attributes) || ""}
                    label={
                      childItem.label || childItem.page?.data?.attributes?.title
                    }
                    opensSubmenu={hasSubmenu(childItem)}
                  />
                )}
                {childItem && hasSubmenu(childItem) && (
                  <NavBarMenuSubMenu
                    onBack={onBack}
                    item={childItem}
                    onClick={onClick}
                    activeItems={activeItems}
                    isSubMenu
                    onClose={onClose}
                  ></NavBarMenuSubMenu>
                )}
              </li>
            ))}
            {item.overviewLabel && (
              <Button
                size="md"
                variant="neutral"
                onClick={onClose}
                href={getPagePath(item.page?.data?.attributes) || undefined}
                className="!w-full justify-between"
                iconEnd="RightSmall"
              >
                {item.overviewLabel}
              </Button>
            )}
          </ul>
        }
      </div>
    </Transition>
  );
};

export default NavBarMenuSubMenu;
