import { ParsedUrlQuery } from "querystring";
import { UrlObject } from "url";
import { getUTM } from "./";

const addQueryToStringUrl = (
  urlString: string,
  queryParams: Record<string, string>,
) => {
  let hash = "";

  if (urlString.includes("#")) {
    const [newUrl = "", newHash = ""] = urlString.split("#");
    urlString = newUrl;
    hash = newHash;
  }

  const hashString = hash ? `#${hash}` : "";

  if (urlString.includes("?")) {
    const [path, search] = urlString.split("?");
    const params = new URLSearchParams(search);
    const mergedParams = new URLSearchParams({
      ...queryParams,
      ...Object.fromEntries(params),
    });

    return `${path}?${mergedParams.toString()}${hashString}`;
  }

  const params = new URLSearchParams(queryParams);

  if (params.toString()) {
    return `${urlString}?${params.toString()}${hashString}`;
  }

  return `${urlString}${hashString}`;
};

const addQueryToUrlObject = (
  url: UrlObject,
  query?: Record<string, string>,
) => {
  const result: UrlObject = {
    ...(url as UrlObject),
  };
  if (query) {
    result.query = { ...(result.query as ParsedUrlQuery), ...query };
  }
  return result;
};

const addUtmQueryToHref = <T extends string | UrlObject>(
  url: T,
  query?: Record<string, string>,
): T => {
  const utm = getUTM();
  const utmQuery =
    utm &&
    Object.keys(utm).reduce((query, key) => {
      query[`utm_${key}`] = (utm as any)[key];
      return query;
    }, {} as any);
  const mergedQuery = { ...utmQuery, ...query };

  if (typeof url === "string") {
    return addQueryToStringUrl(url, mergedQuery) as T;
  }

  return addQueryToUrlObject(url, mergedQuery) as T;
};

export default addUtmQueryToHref;
