import { ArticleEntity } from "@lib/generated/graphql";
import useLocale from "@lib/hooks/useLocale";
import { getArticlePath } from "@lib/routing";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import Link from "../Link";
import StrapiImage from "../StrapiImage";

interface NavBarArticleProps {
  article: ArticleEntity;
}

const NavBarArticle: FC<NavBarArticleProps> = ({ article }) => {
  const locale = useLocale();
  const href = getArticlePath(locale, article.attributes!.slug!);
  const { t } = useTranslation();
  return (
    <Link href={href} className="group rounded-xs">
      <div className="h-[118px] rounded-xs mb-s relative overflow-hidden">
        <StrapiImage
          fill
          sizes="206px"
          image={article.attributes?.image}
          className="object-cover transition-all group-hover:scale-100 m:scale-105"
        />
      </div>
      <h3 className="text-body-2 mb-xs line-clamp-2 group-hover:text-primary-825 group-active:text-primary-875">
        {article.attributes?.title}
      </h3>
      {article.attributes?.summary && (
        <p className="text-caption-1 mb-3xs line-clamp-2">
          {article.attributes?.summary}
        </p>
      )}
      <span className="text-caption-1 text-opacity-60 text-neutral-675">
        {t("min_read", { min: article.attributes?.readingTime })}
      </span>
    </Link>
  );
};

export default NavBarArticle;
