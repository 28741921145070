/* eslint-disable jsx-a11y/anchor-is-valid */
import addUtmQueryToHref from "@lib/utm/addUtmQueryToHref";
import clsx from "clsx";
import { default as NextLink, LinkProps as NextLinkProps } from "next/link";
import { MouseEventHandler } from "react";

interface LinkProps extends NextLinkProps {
  id?: string | null;
  className?: string;
  target?: string;
  role?: string;
  children?: any;
  onClick?: MouseEventHandler<HTMLAnchorElement>;
}

const isExternal = (href: string) => {
  return href.startsWith("http");
};

const Link = ({
  className,
  children,
  onClick,
  id,
  target,
  href,
  role,
  ...props
}: LinkProps & React.RefAttributes<HTMLAnchorElement>) => {
  const external = isExternal(href?.toString() || "");
  let linkTarget = target;
  let rel;

  if (external) {
    linkTarget = target ?? "_blank";
    rel = "noopener noreferrer";
  }

  const _href = addUtmQueryToHref(href);

  return (
    <NextLink
      {...props}
      href={_href}
      role={role}
      id={id || undefined}
      target={linkTarget}
      rel={rel}
      className={clsx("focusable", className, {
        "rounded-xs": !className?.includes("rounded"),
      })}
      onClick={onClick}
    >
      {children}
    </NextLink>
  );
};

export default Link;
