import { useMemo } from "react";
import useWindowSize from "./useWindowSize";

const useBreakpoints = () => {
  const { width } = useWindowSize();

  return useMemo(() => {
    if (!width) return { xs: false, s: false, m: false, l: false };
    return {
      xs: width < 480,
      s: width >= 480 && width < 960,
      m: width >= 960 && Number(width) < 1560,
      l: width >= 1560,
    };
  }, [width]);
};

export default useBreakpoints;
