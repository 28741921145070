import clsx from "clsx";
import {
  cloneElement,
  ComponentProps,
  ElementType,
  FC,
  PropsWithChildren,
  ReactElement,
} from "react";
import Icon, { IconType } from "../Icon";
import Link from "../internal/Link";
import Loader from "../Loader";
import { ButtonProps, ButtonSize, ButtonVariant } from "./types";

const disabledCommon = "disabled:opacity-50 disabled:cursor-not-allowed";

const focusCommon = "focusable";

const ghostCommon = "w-fit";

const variants: Record<ButtonVariant, string> = {
  primary: `text-neutral-0 bg-primary-800 border border-primary-800 hover:bg-primary-825 hover:border-primary-825 active:bg-primary-850 active:border-primary-850 ${disabledCommon} ${focusCommon} focus-visible:outline-primary-800`,
  primary_inverted: `text-neutral-675 bg-neutral-0 hover:bg-neutral-25 active:bg-neutral-50 ${disabledCommon} ${focusCommon} focus-visible:outline-neutral-0`,
  ghost: `text-primary-800 bg-transparent hover:text-primary-825 active:text-primary-850 ${disabledCommon} ${focusCommon} focus-visible:outline-primary-800 ${ghostCommon}`,
  ghost_danger: `text-error-700 bg-transparent hover:border-error-750 active:text-error-775 ${disabledCommon} ${focusCommon} focus-visible:outline-primary-800 ${ghostCommon}`,
  ghost_inverted: `text-neutral-0 bg-transparent hover:text-neutral-25 active:text-neutral-50 ${disabledCommon} ${focusCommon} focus-visible:outline-neutral-0 ${ghostCommon}`,
  danger: `text-neutral-0 bg-error-700 hover:bg-error-750 active:bg-error-775 ${disabledCommon} ${focusCommon} focus-visible:outline-error-700`,
  outline_danger: `text-error-700 bg-neutral-0 border border-neutral-50 hover:border-error-750 hover:text-error-750 active:border-error-775 active:text-error-775 ${disabledCommon} ${focusCommon}`,
  outline_primary: `text-primary-800 bg-neutral-0 border border-neutral-50 hover:border-primary-825 hover:text-primary-825 active:border-primary-850 active:text-primary-850 ${disabledCommon} ${focusCommon} focus-visible:outline-primary-800`,
  outline_inverted: `text-neutral-0 border border-neutral-0/50 hover:border-neutral-0 active:border-neutral-0/60 ${disabledCommon} ${focusCommon} focus-visible:outline-neutral-0`,
  icon: `text-primary-800 bg-neutral-0 !rounded-full shadow-1 !p-2s hover:text-neutral-0 hover:bg-primary-825 active:text-neutral-0 active:bg-primary-850 ${disabledCommon} ${focusCommon} focus-visible:outline-primary-800 focus-visible:outline-offset-0`,
  rose_red: `text-neutral-0 bg-rose-425 hover:bg-rose-675 active:bg-rose-750 ${disabledCommon} ${focusCommon} focus-visible:outline-rose-425`,
  han_purple: `text-neutral-0 bg-purple-725 hover:bg-purple-775 active:bg-purple-850 ${disabledCommon} ${focusCommon} focus-visible:outline-purple-725`,
  ghost_han_purple: `text-purple-725 bg-transparent hover:text-purple-775 active:text-purple-850 ${disabledCommon} ${focusCommon} focus-visible:outline-purple-725  ${ghostCommon}`,
  marigold: `text-neutral-675 bg-marigold-100 hover:bg-marigold-325 active:bg-marigold-525 ${disabledCommon} ${focusCommon} focus-visible:outline-marigold-100`,
  mindaro: `text-neutral-675 bg-mindaro-325 hover:bg-mindaro-575 active:bg-mindaro-675 ${disabledCommon} ${focusCommon} focus-visible:outline-mindaro-325`,
  ghost_mindaro: `text-mindaro-325 bg-transparent hover:text-mindaro-575 active:text-mindaro-675 ${disabledCommon} ${focusCommon} focus-visible:outline-mindaro-325 ${ghostCommon}`,
  neutral: `text-neutral-425 bg-neutral-12 border border-neutral-12 hover:bg-neutral-25 hover:border-neutral-25 active:bg-neutral-50 active:border-neutral-50 ${disabledCommon} ${focusCommon}`,
};

const invertedLoader: Record<ButtonVariant, boolean> = {
  primary: true,
  primary_inverted: false,
  ghost: false,
  ghost_inverted: true,
  ghost_danger: false,
  danger: true,
  outline_danger: false,
  outline_primary: false,
  outline_inverted: true,
  icon: false,
  rose_red: true,
  han_purple: true,
  ghost_han_purple: false,
  marigold: false,
  mindaro: false,
  ghost_mindaro: false,
  neutral: false,
};

const sizes: Record<ButtonSize, string> = {
  lg: "px-2s s:px-3m py-2s text-button-1 font-normal rounded-s",
  md: "px-2s s:px-3s py-[10px] s:py-[13px] text-button-2 font-book rounded-s",
  sm: "px-2s py-[7px] text-app-button-1 font-book rounded-xs",
  xs: "px-2xs py-[3px] text-app-body-2 font-book rounded-xs",
};

const iconEndSizes: Record<ButtonSize, string> = {
  lg: "ml-3xs",
  md: "ml-2xs !text-button-1",
  sm: "ml-2xs !text-button-1",
  xs: "ml-xs !text-app-body-2",
};

const iconStartSizes: Record<ButtonSize, string> = {
  lg: "mr-3xs",
  md: "mr-2xs !text-button-1",
  sm: "mr-2xs !text-button-1",
  xs: "mr-xs !text-app-body-2",
};

const ButtonIcon: FC<{
  type?: IconType | ReactElement | null;
  className?: string;
  size: ButtonSize;
  color?: string;
}> = ({ type, className, size, color }) => {
  let iconSize = 20;
  let iconClass = "";
  if (size === "lg") {
    iconSize = 24;
  } else if (size === "xs") {
    iconSize = 16;
    iconClass = "flex justify-center items-center w-2s h-2s";
  }
  if (typeof type === "string") {
    return (
      <Icon
        size={iconSize}
        className={clsx(className, iconClass)}
        type={type}
        fill={color || "currentColor"}
      />
    );
  }

  if (type) {
    return cloneElement(
      type as unknown as ReactElement<ComponentProps<typeof Icon>>,
      {
        className,
        size: iconSize,
        fill: color,
      },
    );
  }
  return null;
};

const Button = <C extends ElementType>({
  variant = "primary",
  size = "lg",
  iconStart,
  iconEnd,
  type = "button",
  disabled,
  children,
  className,
  as,
  isLoading,
  onClick,
  iconColor,
  fullWidth = false,
  paddingX = true,
  center = true,
  ...props
}: PropsWithChildren<ButtonProps<C>>) => {
  const variationClass = variants[variant];
  const sizeClass = sizes[size];
  const iconOnly = !children && (iconStart || iconEnd);
  const isGhost = variant.startsWith("ghost");
  const combineClassName = clsx(
    "transition flex items-center no-underline",
    variationClass,
    sizeClass,
    className,
    {
      "justify-center": center,
      "!px-[3px]": iconOnly && size === "xs",
      "!px-[7px]": iconOnly && size === "sm",
      "!p-3xs": iconOnly && size === "md",
      "!p-s": iconOnly && size === "lg",
      "m:w-fit": !fullWidth,
      "w-full": !iconOnly && !isGhost,
      "!px-none": !paddingX,
      "hover:border-neutral-0 hover:border-opacity-40":
        variant === "ghost_inverted" && size === "sm",
    },
  );

  let content: ReactElement | null = null;

  if (variant === "icon") {
    const icon = iconStart || iconEnd;
    content = <ButtonIcon size={size} type={icon} color={iconColor} />;
  } else {
    content = (
      <>
        {iconStart && (
          <ButtonIcon
            color={iconColor}
            className={clsx(iconStartSizes[size], {
              "!m-none": iconOnly,
            })}
            size={size}
            type={iconStart}
          />
        )}
        {children}

        {iconEnd && (
          <ButtonIcon
            color={iconColor}
            className={clsx(iconEndSizes[size], {
              "!m-none": iconOnly,
            })}
            size={size}
            type={iconEnd}
          />
        )}
      </>
    );
  }

  if (isLoading) {
    if (isLoading && iconStart) {
      content = (
        <div className="flex relative justify-center items-center">
          <Loader
            size="md"
            inverted={invertedLoader[variant]}
            className={iconStartSizes[size]}
          />
          {children}
        </div>
      );
    } else {
      content = (
        <div className="flex relative justify-center items-center">
          <Loader
            size="md"
            className="absolute"
            inverted={invertedLoader[variant]}
          />
          <div className="invisible flex items-center">{content}</div>
        </div>
      );
    }
  }

  const Component: ElementType = as ? as : props.href ? Link : "button";

  return (
    <Component
      disabled={!!disabled}
      type={type || undefined}
      className={combineClassName}
      onClick={isLoading ? undefined : onClick}
      {...props}
    >
      {content}
    </Component>
  );
};

export default Button;
