import { FathomEvent } from "@lib/constants";
import { Navigation } from "@lib/generated/graphql";
import { FC } from "react";
import Container from "../Container";
import NavBarLink from "./NavBarLink";
import { NavBarMenuItem } from "./types";
import { trackEvent } from "@justhome/common";

interface NavBarTabsProps {
  items: Navigation["items"];
  onSelect: (item: NavBarMenuItem) => void;
}
const NavBarTabs: FC<NavBarTabsProps> = ({ items, onSelect }) => {
  const handleClick = (item: NavBarMenuItem) => {
    trackEvent(FathomEvent.NAVIGATION_TABS);
    onSelect(item);
  };

  return (
    <Container className="bg-neutral-0 border-b border-neutral-25 overflow-x-auto min-[1260px]:hidden">
      <ul className="flex w-full justify-around px-s py-2xs">
        {items?.map(
          (item, index) =>
            item && (
              <li key={item.id ?? index}>
                <NavBarLink
                  onClick={() => handleClick(item)}
                  onMouseEnter={() => {}}
                  label={item.mobileLabel}
                  page={item.page}
                  isLink={!item?.items?.length}
                />
              </li>
            ),
        )}
      </ul>
    </Container>
  );
};

export default NavBarTabs;
