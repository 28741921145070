import clsx from "clsx";
import { PropsWithChildren, forwardRef } from "react";

const Container = forwardRef<
  HTMLDivElement,
  {
    className?: string;
    padding?: boolean;
    fullWidth?: boolean;
  } & PropsWithChildren
>(function Container({ children, className, padding, fullWidth }, ref) {
  return (
    <div
      ref={ref}
      className={clsx("w-full space-m m:px-s", className, {
        "max-w-[1600px] mx-auto ": !fullWidth,
        "px-s m:px-m": padding,
      })}
    >
      {children}
    </div>
  );
});

export default Container;
