import { ParsedPartner } from "@justhome/common";
import { createContext, FC, PropsWithChildren } from "react";

export interface PartnerState {
  partner?: ParsedPartner;
}

export type PartnerContextValue = PartnerState;

export const PartnerContext = createContext<PartnerContextValue>({});

PartnerContext.displayName = "Partner";

export const PartnerProvider: FC<
  PropsWithChildren<{ initialState: PartnerState }>
> = ({ children, initialState }) => {
  return (
    <PartnerContext.Provider value={initialState}>
      {children}
    </PartnerContext.Provider>
  );
};
