import { Button, Icon, IconType } from "@justhome/react-ui";
import { Maybe } from "@lib/generated/graphql";
import { getPagePath } from "@lib/routing";
import Image from "next/image";
import { FC, PropsWithChildren } from "react";
import Link from "../Link";

const FooterLink = ({ href, label }: { href: string; label: string }) => {
  return (
    <li className="hover:cursor-pointer">
      <Link href={href}>{label}</Link>
    </li>
  );
};

const FooterLinks: FC<PropsWithChildren<{ title: string }>> = ({
  title,
  children,
}) => {
  return (
    <div className="flex-1">
      <p className="text- text-body-1 mb-3xs m:mb-3s whitespace-nowrap">
        {title}
      </p>
      <ul className="text-button-2 space-y-s">{children}</ul>
    </div>
  );
};

const FooterSocialLink: FC<{ url: string; icon: string }> = ({ icon, url }) => {
  return (
    <Button
      href={url}
      size="md"
      aria-label={icon}
      variant="ghost_inverted"
      iconStart={<Icon type={(icon + "Square") as IconType} fill="#FFF" />}
    ></Button>
  );
};

interface FooterProps {
  copyright: string;
  socialLinks?: Maybe<Maybe<{ url: string; icon: string }>[]>;
  secondaryLinks?: Maybe<
    Maybe<{ slug?: Maybe<string>; title: string }> | undefined
  >[];
}

const Footer: FC<PropsWithChildren<FooterProps>> = ({
  children,
  copyright,
  socialLinks,
  secondaryLinks,
}) => {
  return (
    <footer className="p-s m:px-3xl py-2m bg-pattern-6-dark bg-cadet-900 text-neutral-0 rounded-t-m rounded-b-none s:rounded-m mt-2s m:mt-m s:my-m">
      <div className="m:flex gap flex-wrap">
        <div className="m:mr-m">
          <Link href="/" className="mb-m block">
            <Image
              src="/assets/img/logo-new-inverted.svg"
              alt="Justhome"
              width="136"
              height="32"
            />
          </Link>

          <div className="flex mt-2s justify-start mb-m m:mb-none">
            {socialLinks?.map(
              (link) => link && <FooterSocialLink key={link.icon} {...link} />,
            )}
          </div>
        </div>

        <div className="flex flex-col s:flex-row s:flex-wrap flex-1 gap-m s:gap">
          {children}
        </div>
      </div>
      <div className="flex mt-m m:mt-2s text-caption-1 gap-s flex-wrap">
        <p>&copy; {copyright}</p>
        {secondaryLinks?.map((link) => {
          const href = getPagePath(link);
          return (
            link &&
            href && (
              <Link key={link.title} href={href}>
                {link.title}
              </Link>
            )
          );
        })}
      </div>
    </footer>
  );
};

export default Object.assign(Footer, {
  Links: FooterLinks,
  Link: FooterLink,
  SocialLink: FooterSocialLink,
});
