import { default as ButtonBlock } from "@components/blocks/Button";
import { Transition } from "@headlessui/react";
import { useBreakpoints } from "@justhome/common/hooks";
import { Navigation } from "@lib/generated/graphql";
import useDisableScroll from "@lib/hooks/useDisableScroll";
import useKeydown from "@lib/hooks/useKeydown";
import usePartnerContext from "@lib/hooks/usePartnerContext";
import clsx from "clsx";
import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import BenefitsBar from "../BenefitsBar";
import BurgerMenuButton from "../BurgerMenuButton";
import Container from "../Container";
import NavBarLink from "./NavBarLink";
import NavBarLogo from "./NavBarLogo";
import NavBarMenu from "./NavBarMenu";
import NavBarPanel from "./NavBarPanel";
import NavBarTabs from "./NavBarTabs";
import { NavBarMenuItem } from "./types";

interface NavBarProps {
  data: Navigation;
  isMinimal?: boolean;
}

const useStickyHeader = (topSection) => {
  const [top, setTop] = useState(0);
  const [isSticky, setIsSticky] = useState(false);
  const lastScrollY = useRef(0);

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = Math.max(0, window.scrollY);
      const dy = scrollY - lastScrollY.current;
      const maxTop = topSection.current?.clientHeight || 0;

      const isTopSectionScrolled = scrollY > maxTop;

      if (!isSticky && isTopSectionScrolled) {
        setIsSticky(true);
      } else if (isSticky && !isTopSectionScrolled) {
        setIsSticky(false);
      }

      if (dy > 0) {
        setTop((top) => Math.max(-maxTop, top - dy));
      } else {
        setTop((top) => Math.min(top - dy, 0));
      }

      lastScrollY.current = scrollY;
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  });

  return {
    headerStyle: {
      transform: `translateY(${top}px)`,
    },
    isSticky,
  };
};

const NavBar: FC<NavBarProps> = ({ data, isMinimal = false }) => {
  const { xs, s } = useBreakpoints();
  const isMobile = xs || s;
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [activeItem, setActiveItem] = useState<any>(null);
  const [activeMenuItems, setActiveMenuItems] = useState<NavBarMenuItem[]>([
    data,
  ]);
  const [openedByHover, setOpenedByHover] = useState(false);
  const { partner } = usePartnerContext();
  const timeoutRef = useRef<number>();
  const headerRef = useRef<HTMLDivElement>(null);
  const benefitsBarRef = useRef(null);
  const headerItems = useMemo(() => {
    return data.items?.filter((item) => item?.showInHeader);
  }, [data]);
  const { isSticky, headerStyle } = useStickyHeader(benefitsBarRef);

  useKeydown("Escape", () => {
    setActiveItem(null);
    setIsMenuOpen(false);
    setActiveMenuItems([data]);
  });

  useDisableScroll(isMenuOpen);

  useEffect(() => {
    setActiveMenuItems([data]);
  }, [data]);

  const handleMenuItemSelect = useCallback(
    (item: NavBarMenuItem) => {
      if (item.items?.length) {
        setActiveMenuItems((items) => [...items, item]);
      } else {
        setActiveMenuItems([data]);
        setIsMenuOpen(false);
      }
    },
    [data],
  );

  const handleMenuBack = useCallback(() => {
    setActiveMenuItems((items) => items.slice(0, -1));
  }, []);

  const handleItemClick = (item: NavBarMenuItem) => {
    if (!activeItem) {
      setActiveItem(item);
    } else if (activeItem === item) {
      setActiveItem(null);
      setOpenedByHover(false);
    }
  };

  const handleItemHover = (item: NavBarMenuItem) => {
    setIsMenuOpen(false);
    setActiveMenuItems([data]);

    if (item.items?.length) {
      setActiveItem(item);
      setOpenedByHover(true);
    } else {
      setActiveItem(null);
      setOpenedByHover(false);
    }
  };

  const handleMouseLeave = () => {
    if (openedByHover) {
      timeoutRef.current = window.setTimeout(() => {
        setActiveItem(null);
        setOpenedByHover(false);
      }, 500);
    }
  };

  const handleMouseEnter = () => {
    clearTimeout(timeoutRef.current);
  };

  const handleTabSelect = (item: NavBarMenuItem) => {
    setActiveMenuItems([data, item]);
    setIsMenuOpen(true);
  };

  const handleToggleMenu = () => {
    if (isMenuOpen) {
      setIsMenuOpen(false);
      setTimeout(() => {
        setActiveMenuItems([data]);
      }, 500);
    } else {
      setActiveItem(null);
      setOpenedByHover(false);
      setIsMenuOpen(true);
    }
  };

  return (
    <>
      <nav
        role="navigation"
        onMouseLeave={handleMouseLeave}
        onMouseEnter={handleMouseEnter}
        style={headerStyle}
        className={clsx("sticky top-none z-30 bg-neutral-0", {
          "shadow-3": isSticky,
        })}
      >
        {data.benefitsBar && (
          <BenefitsBar ref={benefitsBarRef} data={data.benefitsBar} />
        )}
        <div className="border-b border-neutral-25">
          <Container
            ref={headerRef}
            padding
            className="flex justify-between items-center gap-xs s:gap-3s transition-all m:py-s py-xs"
          >
            <div className="flex items-center flex-1 m:flex-none overflow-hidden">
              <BurgerMenuButton
                className="mr-3xs -m:ml-2xs"
                isOpen={isMenuOpen}
                onClick={handleToggleMenu}
              />
              <div className="w-full relative h-m m:h-auto">
                <Transition show={!isMobile || !isSticky || isMinimal}>
                  <div
                    className={clsx(
                      "absolute m:static inset-none flex items-center justify-center pr-3m m:pr-none transition-transform translate-y-none duration-500 data-[enter]:translate-y-3xl data-[leave]:translate-y-3xl",
                    )}
                  >
                    <NavBarLogo
                      href="/"
                      className="mx-auto"
                      partner={partner}
                    />
                  </div>
                </Transition>
                <Transition show={!isMinimal && isSticky}>
                  <div className="m:hidden absolute inset-none flex items-center justify-end transition-transform duration-500 translate-y-none data-[enter]:-translate-y-3xl data-[leave]:-translate-y-3xl">
                    {data.button && (
                      <ButtonBlock
                        prefetch={false}
                        {...data.button}
                        size="sm"
                        className="!w-fit"
                      ></ButtonBlock>
                    )}
                  </div>
                </Transition>
              </div>
            </div>
            {!(partner || isMinimal) && (
              <div className="hidden min-[1260px]:block transition-opacity">
                <ul className="flex gap-2xs">
                  {headerItems?.map(
                    (item) =>
                      item && (
                        <li key={item.label}>
                          <NavBarLink
                            isLink={!item.items?.length}
                            onClick={() => handleItemClick(item)}
                            onMouseEnter={() => handleItemHover(item)}
                            label={item.label}
                            page={item.page}
                            isActive={item === activeItem}
                          />

                          {item === activeItem && (
                            <NavBarPanel
                              item={item}
                              articles={data.articles?.data}
                            />
                          )}
                        </li>
                      ),
                  )}
                </ul>
              </div>
            )}
            <div className="hidden m:block">
              {!isMinimal && data.button && (
                <ButtonBlock {...data.button}></ButtonBlock>
              )}
            </div>
          </Container>
        </div>

        {!isMinimal && (
          <NavBarTabs items={headerItems} onSelect={handleTabSelect} />
        )}
      </nav>

      <NavBarMenu
        onBack={handleMenuBack}
        activeItems={activeMenuItems}
        onSelectItem={handleMenuItemSelect}
        headerRef={headerRef}
        navigation={data}
        isOpen={isMenuOpen}
        onClose={handleToggleMenu}
      />
    </>
  );
};

export default NavBar;
