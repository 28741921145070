import { Button } from "@justhome/react-ui";
import {
  ArticleEntity,
  ComponentBlocksNavigationLevel1,
} from "@lib/generated/graphql";
import useLocale from "@lib/hooks/useLocale";
import { getLearningCenterPath, getPagePath } from "@lib/routing";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import Container from "../Container";
import NavBarArticle from "./NavBarArticle";
import NavBarItem from "./NavBarItem";

interface NavBarPanelProps {
  item: ComponentBlocksNavigationLevel1;
  articles?: ArticleEntity[] | null;
}
const NavBarPanel: FC<NavBarPanelProps> = ({ item, articles }) => {
  const { t } = useTranslation();
  const locale = useLocale();
  return (
    <div className="w-full absolute top-full left-none">
      <div className="absolute bottom-none left-none right-none h-m shadow-3 rounded-b-m -z-10"></div>
      <div className="overflow-hidden rounded-b-m bg-neutral-0 w-full flex justify-center">
        <Container padding className="w-full flex">
          <div className="py-m pr-m flex-1">
            <ul className="grid grid-cols-12 gap-2s">
              {item.items?.map(
                (subItem, index) =>
                  subItem && (
                    <NavBarItem
                      className="col-span-4"
                      key={subItem.id ?? index}
                      label={subItem.label}
                      page={subItem.page}
                      icon={subItem.icon?.type}
                    />
                  ),
              )}
            </ul>
            {item.overviewLabel && (
              <Button
                className="col-span-12 !w-full mt-2s"
                iconEnd="RightSmall"
                size="md"
                variant="neutral"
                href={getPagePath(item.page?.data?.attributes)}
              >
                {item.overviewLabel}
              </Button>
            )}
          </div>
          {articles && (
            <div className="w-[464px] py-m pl-m flex-none bg-neutral-12 relative grid grid-cols-2 gap-2s">
              {articles.map((article, index) => (
                <NavBarArticle key={article.id ?? index} article={article} />
              ))}
              <Button
                variant="ghost"
                paddingX={false}
                iconEnd="RightSmall"
                size="md"
                href={getLearningCenterPath(locale)}
              >
                {t("see_all")}
              </Button>
              <span className="bg-neutral-12 absolute left-full top-none bottom-none w-[2000px]"></span>
            </div>
          )}
        </Container>
      </div>
    </div>
  );
};

export default NavBarPanel;
