import { useBreakpoints } from "@justhome/common/hooks";
import { FathomEvent } from "@lib/constants";

import clsx from "clsx";
import { useTranslation } from "next-i18next";
import { FC, MouseEvent, MouseEventHandler } from "react";
import styles from "./BurgerMenuButton.module.css";
import { trackEvent } from "@justhome/common";

interface BurgerMenuIconProps {
  isOpen: boolean;
  onClick: MouseEventHandler;
  className?: string;
}

const BurgerMenuButton: FC<BurgerMenuIconProps> = ({
  isOpen,
  onClick,
  className,
}) => {
  const { xs, s } = useBreakpoints();
  const { t } = useTranslation();

  const handleClick = (e: MouseEvent) => {
    if (xs || s) {
      trackEvent(FathomEvent.BURGER_MENU);
    }

    onClick(e);
  };

  return (
    <button
      className={clsx("p-3xs focusable rounded-s", className)}
      onClick={handleClick}
      aria-expanded={isOpen}
      aria-label={t("open_menu")}
    >
      <div
        className={clsx("text-primary-800", styles.button, {
          [styles.open as string]: isOpen,
        })}
      >
        <span className={styles.bar}></span>
        <span className={styles.bar}></span>
        <span className={styles.bar}></span>
      </div>
    </button>
  );
};

export default BurgerMenuButton;
