import { Icon } from "@justhome/react-ui";
import {
  ComponentBlocksBenefitsBar,
  ComponentBlocksListItemThree,
} from "@lib/generated/graphql";
import clsx from "clsx";
import { FC, forwardRef } from "react";
import Container from "../Container";
import Link from "../Link";

interface BenefitsBarItemProps {
  item: ComponentBlocksListItemThree;
}

const BenefitsBarItem: FC<BenefitsBarItemProps> = ({ item }) => {
  const Component = item.href ? Link : "span";

  return (
    <Component
      key={item.id}
      href={item.href!}
      className={clsx("flex items-center last:ml-auto whitespace-nowrap", {
        "underline underline-offset-2": item.href,
        "hidden m:flex": !item.mobileLabel,
      })}
    >
      <Icon type={item?.icon?.type} size="16" className="mr-2xs" />
      <span className="hidden m:inline">{item.label}</span>
      <span className="m:hidden">{item.mobileLabel}</span>
    </Component>
  );
};

interface BenefitsBarProps {
  data: ComponentBlocksBenefitsBar;
}

const BenefitsBar = forwardRef<HTMLDivElement, BenefitsBarProps>(
  function BenefitsBar({ data }, ref) {
    return (
      <div
        ref={ref}
        className="bg-primary-50 py-2xs border-b border-b-neutral-25 text-primary-800 text-body-2"
      >
        <Container padding className="flex w-full gap-3s">
          {data.items?.map(
            (item) => item && <BenefitsBarItem key={item.label} item={item} />,
          )}
        </Container>
      </div>
    );
  },
);

export default BenefitsBar;
