import useGlobalContext from "@lib/hooks/useGlobalContext";
import { getPageEntityPath } from "@lib/routing";
import { FC } from "react";
import { default as BaseFooter } from "../../common/Footer";

const Footer: FC = () => {
  const context = useGlobalContext();
  const footer = context?.siteData?.footerV2?.data?.attributes;
  const secondaryLinks = footer?.secondaryLinks?.data.map(
    (data) => data.attributes,
  );
  if (!footer) {
    return null;
  }

  return (
    <BaseFooter
      copyright={footer.copyright}
      socialLinks={footer.socialLinks}
      secondaryLinks={secondaryLinks}
    >
      {footer?.linkGroups.map((linkGroup) => {
        if (!linkGroup?.title) {
          return null;
        }
        return (
          <BaseFooter.Links title={linkGroup.title} key={linkGroup.title}>
            {linkGroup.links?.map((link) => {
              if (!link || !link.page?.data?.attributes) {
                return null;
              }
              const href = getPageEntityPath(link.page);

              if (!href) {
                return null;
              }

              return (
                <BaseFooter.Link
                  key={link.page.data.attributes.title}
                  href={href}
                  label={link.page.data.attributes.title}
                ></BaseFooter.Link>
              );
            })}
          </BaseFooter.Links>
        );
      })}
    </BaseFooter>
  );
};

export default Footer;
