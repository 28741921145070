import ButtonBlock from "@components/blocks/Button";
import { Transition } from "@headlessui/react";
import { useIsMobile } from "@justhome/common/hooks";
import { Button } from "@justhome/react-ui";
import { Navigation } from "@lib/generated/graphql";
import useLocale from "@lib/hooks/useLocale";
import usePartnerContext from "@lib/hooks/usePartnerContext";
import clsx from "clsx";
import Image from "next/image";
import { Locale } from "next/router";
import { FC, PropsWithChildren, RefObject, useEffect, useState } from "react";
import BurgerMenuButton from "../BurgerMenuButton";
import NavBarLogo from "./NavBarLogo";
import NavBarMenuSubMenu from "./NavBarMenuSubMenu";
import { NavBarMenuItem } from "./types";

interface LocaleButtonProps extends PropsWithChildren {
  onClick: VoidFunction;
  href: string;
  imageSrc: string;
  locale: Locale;
}

const LanguageButton: FC<LocaleButtonProps> = ({
  children,
  onClick,
  href,
  imageSrc,
  locale,
}) => {
  return (
    <Button
      locale={locale}
      href={href}
      onClick={onClick}
      iconStart={
        <Image
          className="w-2s h-2s object-cover"
          src={imageSrc}
          alt=""
          width="20"
          height="20"
        />
      }
      size="sm"
      variant="ghost"
      className="!w-full"
    >
      {children}
    </Button>
  );
};

interface NavBarMenuProps {
  navigation: Navigation;
  isOpen: boolean;
  onClose: VoidFunction;
  onBack: VoidFunction;
  activeItems: NavBarMenuItem[];
  onSelectItem: (item: NavBarMenuItem) => void;
  headerRef: RefObject<HTMLElement>;
}

const NavBarMenu: FC<NavBarMenuProps> = ({
  navigation,
  isOpen,
  onClose,
  headerRef,
  onSelectItem,
  activeItems,
  onBack,
}) => {
  const [top, setTop] = useState(0);
  const [paddingTop, setPaddingTop] = useState(0);
  const [width, setWidth] = useState(400);
  const isMobile = useIsMobile();
  const { partner } = usePartnerContext();
  const locale = useLocale();

  useEffect(() => {
    if (headerRef.current) {
      const { top, bottom } = headerRef.current.getBoundingClientRect();

      const { left } = (
        headerRef.current as HTMLElement
      ).getBoundingClientRect();

      if (isMobile) {
        setTop(bottom);
        setPaddingTop(0);
      } else {
        setTop(0);
        setPaddingTop(top);
      }

      setWidth(left + Math.min(400, document.body.offsetWidth));
    }
  }, [isOpen, headerRef, isMobile]);

  return (
    <>
      <Transition appear show={isOpen}>
        <div
          onClick={onClose}
          role="none"
          className="transform-opacity duration-300 fixed inset-none bg-neutral-875 bg-opacity-25 z-50 opacity-100 data-[enter]:opacity-0 data-[leave]:opacity-0"
          style={{ top }}
        ></div>
      </Transition>
      <Transition appear unmount={false} show={isOpen}>
        <div
          style={{ width, top }}
          className="fixed left-none bottom-none w-screen s:w-[400px] z-50 bg-neutral-0 s:shadow-3 flex justify-stretch m:justify-end transition-all duration-300 ease-out -translate-x-none data-[enter]:-translate-x-full data-[closed]:-translate-x-full data-[leave]:-translate-x-full"
        >
          <nav
            role="navigation"
            className="h-full flex flex-col overflow-hidden w-full s:w-[400px]"
            style={{ paddingTop }}
          >
            <div className="px-s pt-s hidden m:flex items-center border-t border-t-transparent">
              <BurgerMenuButton isOpen onClick={onClose} className="mr-3xs" />
              <NavBarLogo href="/" partner={partner} />
            </div>
            <div
              className={clsx(
                "relative flex-1 transition-transform ease-in-out duration-300",
                {
                  "-translate-x-full": activeItems.length === 1,
                  "-translate-x-[200%]": activeItems.length === 2,
                  "-translate-x-[300%]": activeItems.length === 3,
                },
              )}
            >
              <NavBarMenuSubMenu
                onClose={onClose}
                onBack={onBack}
                activeItems={activeItems}
                item={navigation}
                onClick={onSelectItem}
              ></NavBarMenuSubMenu>
            </div>
            <div className="space-y-2xs px-s py-3s">
              {navigation.button && (
                <ButtonBlock
                  {...navigation.button}
                  className="!w-full"
                  size="md"
                />
              )}
              {locale !== "en" && (
                <LanguageButton
                  href="/"
                  locale="en"
                  onClick={onClose}
                  imageSrc="/assets/img/flag-en.png"
                >
                  English
                </LanguageButton>
              )}
              {locale === "en" && (
                <LanguageButton
                  href="/"
                  locale="de"
                  onClick={onClose}
                  imageSrc="/assets/img/flag-de.png"
                >
                  Deutsch
                </LanguageButton>
              )}
            </div>
          </nav>
        </div>
      </Transition>
    </>
  );
};

export default NavBarMenu;
