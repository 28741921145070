import { UploadFileEntityResponse } from "@lib/generated/graphql";
import { useTranslation } from "next-i18next";
import Image, { ImageProps } from "next/image";
import { FC } from "react";

export interface StrapiImageProps extends Omit<Omit<ImageProps, "src">, "alt"> {
  image?: UploadFileEntityResponse | null;
  alt?: string;
}

const parseAltText = (locale: string, altText: string) => {
  try {
    const altData: {
      [key: string]: string;
    } = JSON.parse(altText);
    return altData[locale] ? altData[locale] : altText;
  } catch (e) {
    return altText;
  }
};

const StrapiImage: FC<StrapiImageProps> = ({
  image,
  width,
  height,
  alt,
  ...props
}) => {
  const { i18n } = useTranslation();
  if (!image?.data?.attributes?.url) {
    return null;
  }
  const intrinsicHeight = props.fill
    ? height
    : height || (image.data.attributes.height as number);
  const intrinsicWidth = props.fill
    ? width
    : width || (image.data.attributes.width as number);

  if (typeof alt === "undefined") {
    alt = parseAltText(
      i18n.language,
      image.data.attributes.alternativeText || "",
    );
  }

  return (
    <Image
      {...props}
      alt={alt}
      src={image.data.attributes.url}
      height={intrinsicHeight}
      width={intrinsicWidth}
    />
  );
};

export default StrapiImage;
