import { useEffect, useRef } from "react";

const useKeydown = (key: string, cb: (event: KeyboardEvent) => void) => {
  const callbackRef = useRef<(event: KeyboardEvent) => void>();

  useEffect(() => {
    callbackRef.current = cb;
  }, [cb]);

  useEffect(() => {
    const handleEvent = (e: KeyboardEvent) => {
      if (e.key === key && callbackRef.current) {
        callbackRef.current(e);
      }
    };
    window.addEventListener("keydown", handleEvent);
    return () => {
      window.removeEventListener("keydown", handleEvent);
    };
  }, [key]);
};

export default useKeydown;
